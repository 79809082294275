import { Path } from './Path.js';
import { GeoFire } from 'geofire';
import { GeofireTools } from './GeofireTools.js';
import { DateTime } from 'luxon';

/**
 * Takes in a master location directory id (or ids) and returns all necessary overlapping notes
 * If an update object is passed in, add overlapping_note attribute to the update object
 *
 * @param {Array<string>} locationDirectoryIds - an array of master location directory ids to search
 * @param {Object} options
 * @param {Object} [options.update] - the update object to add the overlapping_note attribute to
 * @param {Object} options.nodes - the nodes object
 * @param {string} options.jobId - the current job id
 * @param {string} options.userGroup - the user group
 * @param {Object} options.jobStyles - the job styles object
 * @param {function} options.onProgress - a function to be called with text to notify the user of progress
 * @returns {Promise<Object>}
 *
 */
export async function AddOverlappingNotes(locationDirectoryIds, options) {
  const update = options?.update;
  const nodes = options?.nodes;
  const currJobId = options?.jobId;
  const userGroup = options?.userGroup;
  const jobStyles = options?.jobStyles;
  const onProgress = options?.onProgress ?? (() => {});

  let jobList = {};

  if (!locationDirectoryIds || !nodes || !userGroup) return [{ text: 'Please Select a Location Directory to import overlapping notes.' }];
  // check to see if there are any nodes in the master location directory that are within 36ft (number is feet -> km)
  const overlappingJobs = {};
  const overlappingNodes = {};
  let jobCenter = null;
  let jobRadius = null;

  // loop through all of the master location directories and get all of the nodes that match in it
  for (let locationDirectoryId of locationDirectoryIds) {
    // Get the shared directories for the user's company
    const sharedDirectoryData = (
      await firebase.firestore().doc(`companies/${userGroup}/shared_directories/${locationDirectoryId}`).get()
    ).data();

    // Check if the location directory is a shared directory and correctly set the companyId
    const companyId = sharedDirectoryData?.owner_company || userGroup;

    // Get the radius to check from the master location directory info, or use 36 ft. if it's not set
    // If a master location directory didn't have a radius to check in the info, we used to have a fallback to check if the node's geohash matched the geohash of the record in the master location directory. With our level of precision, this equated to a radius of ~2' which was smaller than changes in the Google Maps API aerial imagery.
    let masterLocationDirectoryInfo = (
      await firebase.firestore().doc(`companies/${companyId}/directories/${locationDirectoryId}`).get()
    ).data();
    onProgress(`Checking ${masterLocationDirectoryInfo.name} for Overlapping...`);
    let masterLocationDirectoryRadius = masterLocationDirectoryInfo?.radius ?? 36;
    if (!jobCenter) {
      const bounds = new google.maps.LatLngBounds();
      for (let nodeId in nodes) {
        bounds.extend(new google.maps.LatLng(nodes[nodeId].latitude, nodes[nodeId].longitude));
      }
      jobCenter = bounds.getCenter();
      jobRadius = google.maps.geometry.spherical.computeDistanceBetween(bounds.getNorthEast(), jobCenter) / 1000;
    }
    const geoData = await new GeoFire(
      firebase.firestore().collection(`companies/${companyId}/directories/${locationDirectoryId}/locations`)
    ).once({ radius: jobRadius + (masterLocationDirectoryRadius * 0.3048) / 1000, center: [jobCenter.lat(), jobCenter.lng()] });
    for (const key in geoData) {
      const item = geoData[key];
      if (!item.l) continue;
      const ll = new google.maps.LatLng(...item.l);
      for (const nodeId in nodes) {
        const node = nodes[nodeId];
        const distance =
          google.maps.geometry.spherical.computeDistanceBetween(ll, new google.maps.LatLng(node.latitude, node.longitude)) / 0.3048;
        if (distance > masterLocationDirectoryRadius) continue;
        const jobIds = item.d?.j || [];
        for (let jobId of jobIds) {
          if (jobId === currJobId) continue;
          overlappingNodes[nodeId] ??= {};
          overlappingNodes[nodeId][jobId] = true;
          overlappingJobs[jobId] ??= {};
          overlappingJobs[jobId][masterLocationDirectoryInfo.name] = true;
        }
      }
    }
  }

  onProgress(`Loading Job Metadata and Setting Overlapping Notes`);
  for (const nodeId in nodes) {
    const overlappingNotes = [];
    if (overlappingNodes[nodeId]) {
      for (const jobId in overlappingNodes[nodeId]) {
        jobList[jobId] ??= await FirebaseWorker.ref(`photoheight/job_permissions/${userGroup}/jobs/${jobId}`)
          .once('value')
          .then((s) => s.val());
        const note = getNote(jobList, jobId, overlappingJobs, true);
        if (['Draft', 'Canceled'].includes(note.appStatus)) {
          delete overlappingJobs[jobId];
          continue;
        }
        overlappingNotes.push(note);
      }
    }
    overlappingNotes.sort((a, b) => (b.date ?? 0) - (a.date ?? 0));
    const noteUpdate = {};
    overlappingNotes.forEach((note) => {
      noteUpdate[FirebaseWorker.ref().push().key] = note.text;
    });
    update[`nodes/${nodeId}/attributes/overlapping_note/`] = noteUpdate;
    Path.set(nodes[nodeId], 'attributes.overlapping_note', noteUpdate);
    GeofireTools.updateStyle('nodes', nodeId, nodes[nodeId], update, jobStyles);
  }

  const results = [];
  for (const jobId in overlappingJobs) {
    results.push(getNote(jobList, jobId, overlappingJobs));
  }
  results.sort((a, b) => (b.date ?? 0) - (a.date ?? 0));
  if (results.length === 0) results.push({ text: 'No overlapping jobs found.' });
  else results.unshift({ text: 'Overlapping Jobs:' });
  return results;
}

function getNote(jobList, jobId, overlappingJobs, overlapsWith) {
  const jobInfo = jobList[jobId];
  if (!jobInfo) return { text: `Unshared job ${jobId} in ${Object.keys(overlappingJobs[jobId]).join(', ')}` };
  const metadata = jobInfo.metadata ?? {};
  const appStatus = metadata.app_status || 'App Status Not Set';
  const date = metadata.date_submitted ? DateTime.fromMillis(metadata.date_submitted).toFormat('MM/dd/yyyy') : 'No Date Submitted';
  return {
    appStatus,
    date: metadata.date_submitted,
    text: `${overlapsWith ? 'Overlaps with ' : ''}${jobInfo.name} - ${date} (${appStatus})`
  };
}
